<template>
  <div class="top_description">
    <div v-if="info.dyms" class="definition">
      <i class="alert_icon el-icon-info"></i>
      <div class="right_text">
        <div class="title">定义描述</div>
        <div class="info" v-html="info.dyms">{{ info.dyms }}</div>
      </div>
    </div>
    <div v-if="info.zysx" class="attention">
      <i class="alert_icon el-icon-warning"></i>
      <div class="right_text">
        <div class="title">注意事项</div>
        <div class="info" v-html="info.zysx">{{ info.zysx }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "alert_info",
  props: {
    info: {
      type: Object,
      default: function() {
        return { dyms: "", zysx: "" };
      }
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.top_description {
  width: 100%;

  .definition {
    overflow: hidden;
    padding: 10px;
    background-color: #c2def2;
    color: #0e8ebe;
    border-radius: 4px;
    display: flex;
    // border: 1px solid #0E8EBE;
    margin-bottom: 10px;
  }
  .attention {
    overflow: hidden;
    padding: 10px;
    background-color: #ffeed4;
    color: #f44f00;
    border-radius: 4px;
    display: flex;
    // border: 1px solid #F44F00;
    .el-icon-warning {
      color: #ffcc66;
    }
  }
  .alert_icon {
    color: #50bfff;
    font-size: 28px;
    width: 28px;
    margin: auto 20px;
  }
  .right_text {
    width: calc(100% - 50px);
    display: table-cell;
    text-align: left;
  }
  .title {
    font-size: 15px;
    font-weight: 700;
    height: 30px;
    line-height: 20px;
  }
  .info {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
  }
}
</style>
