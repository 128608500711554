import alertInfo from '@comp/alert_info';
import basicUpload from '@comp/basic_upload';
import { mapActions } from 'vuex';
export default {
    components: {
        alertInfo,
        basicUpload
    },
    data() {
        return {
            tableGroupAttributes: {},
            multipleSelection: [],
            tabheight: null,
            searchData: [],
            dateSearchData: [],
            total: 10, // 每页显示几条数据
        };
    },
    mounted() {
        this.handleDataAll(this.selfData, this.commonData);
        this.tableGroupAttributes = this.selfData;
        let search = this.tableGroupAttributes.snSearch.selectData || [];
        if (search.length > 0) {
            search.forEach(item => {
                let type = item.value;
                this.searchData.push({ type: type, value: '' });
            });
        }
        let dateSearch = this.tableGroupAttributes.snSearch.dateData || [];
        if (dateSearch.length > 0) {
            dateSearch.forEach(ele => {
                let type = ele.value;
                this.dateSearchData.push({ type: type, value: '' });
            })
        }
        this.getTableData('mounted');
    },
    methods: {
        ...mapActions([

        ]),
        handleDataAll(self, common) {
            Object.keys(common).forEach(key => {
                // eslint-disable-next-line no-prototype-builtins
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== 'object') return false;
                    if (key === 'snButton') return false;
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
        handleChange(type, value) {
            switch (type) {
                case 'search': // 搜索
                    this[value[1]] = value[0];
                    this.searchData.forEach(item => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.dateSearchData.forEach(ele => {
                        if (ele.type === value[1]) {
                            ele.value = value[0];
                        }
                    });
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'total': // 每页的条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'page': // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case 'searchBtn': // 点击查询按钮
                    this.getTableData();
                    break;
                case 'buttonDel': // 批量删除按钮
                    this.delSome();
                    break;
                case 'buttonNew': // 新建按钮
                    this.toEditPage('', '0');
                    break;
                case 'buttonExport': // 导出按钮
                    this.exportData();
                    break;
                case 'columnEdit': // 编辑按钮
                    this.toEditPage(value, '1');
                    break;
                case 'columnDelete': // 删除按钮
                    this.deleteData(value[0].Id ? value[0].Id : value[0].ID, value[0]); // TODO id
                    break;
                case 'selection': // 表格选择框
                    this.selectData(value[0]);
                    break;
                case 'buttonMapShow': //地图展示
                    this.showPointByMap();
                    break;
                case 'columnText': // 点击文字
                    this.columnTextClick(value);
                    break;
                case 'columnStart': // 点击启动
                    this.isActiveChangeClick(value, '1');
                    break;
                case 'columnStop': // 点击停止
                    this.isActiveChangeClick(value, '0');
                    break;
                default:
                    break;
            }
        },
        toEditPage(value, type) {
            let id = value && value.length > 0 ? value[0].Id ? value[0].Id : value[0].ID : '';
            let bhid = value && value.length > 0 && value[0].BHID ? value[0].BHID : '';
            let cjsj = value && value.length > 0 && value[0].CJSJ ? value[0].CJSJ : '';

            this.$router.push({
                name: this.infoPage,
                query: {
                    type: type,
                    id: id, //addChild ? "" : ID,
                    ItemID: '',
                    basicType: 'picture',
                    sbLx: this.sbLx,
                    bhid: bhid,
                    cjsj: cjsj
                }
            });
        },
        exportData() {
            let url = window.REQUEST_URL + 'DownLoad/FileDownLoad/GetNaturalEnvironmentExport';
            let itemid = this.itemID;
            // let token = localStorage.accessToken;
            let token = localStorage.sysAccessToken;
            let exportUrl = `${url}?itemid=${itemid}&token=${token}`;
            window.open(exportUrl);
        },
        deleteData(id, value) {
            let _this = this;
            let checkBox = id;
            _this
                .$confirm('是否永久删除该条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            _this.delData(checkBox, done, instance, value);
                        } else {
                            done();
                        }
                    }
                })
                .then(() => {})
                .catch(() => {
                    _this.$message({
                        message: '已取消删除',
                        type: 'info'
                    });
                });
        },
        delSome() {
            let checkBox = [];
            this.multipleSelection.forEach(function(o) {
                checkBox.push(o.ID ? o.ID : o.Id); // TODO id
            });
            if (checkBox.length === 0) {
                this.$message({
                    message: '请选择数据项！',
                    type: 'warning'
                });
            } else {
                checkBox = checkBox.join();
                this.deleteData(checkBox);
            }
        },
        delOver(res, done) {
            if (res.IsSuccess) {
                done();
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: '删除失败',
                    type: 'error'
                });
            }
        },
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach(item => {
                    this.multipleSelection.push(item);
                });
            }
        }
    }
};