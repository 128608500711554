const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备",
                prop: "SBMC"
            }, {
                label: "时间",
                prop: "JCSJ"
            }, {
                label: "深度(m)",
                prop: "JCZ"
            }, {
                label: "温度(℃)",
                prop: "WD"
            }, {
                label: "湿度(%)",
                prop: "SD"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
            //     isShow: true,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };